html, body, #root {
  height: 100%;
  width: 100%;  
  margin: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 72px;
  background-color: #f8f9fa;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: border-box;
}
